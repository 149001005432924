body {
	position: relative;
	display: flex;
    flex-direction: column;
	width: 100vw;
	margin: 0;
	background-color: #FFFFFF;
	font: normal 400 24px / 40px "Montserrat", sans-serif;
    color: #000000;
	cursor: default;

    &::-webkit-scrollbar {
        height: 0;
        width: 2px;
        
        &-track {
            background: transparent;
        }
        
        &-button {
            display: none;
        }
        
        &-thumb {
            background-color: $color-primary;
        }
    }

    &.no-scroll {
        overflow: hidden;
    }

	* {
		box-sizing: border-box;
		margin: 0;
	}

    img, svg {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;

        path {
            transition: fill 0.3s;
        }
    }

    iframe, video {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }

    a {
        display: inline-block;
        color: inherit;
        text-decoration: none;
    }

    .disabled,
    .disabled * {
        pointer-events: none;
        cursor: not-allowed;
    }

    .container {
        display: flex;
        justify-content: center;

        &__content {
            width: 1680px;
            max-width: calc(100% - 54px);
        }
    }
}