.footer {
	padding-top: 40px;
	padding-bottom: 60px;
	background-color: #171717;

	@include is-screen-max($breakpoint-sm) {
		padding-top: 30px;
		padding-bottom: 40px;
	}

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 21px;

		@include is-screen-max($breakpoint-xl) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 14px;
		}

		@include is-screen-max($breakpoint-sm) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 30px;
		}
	}

	&__logo {
		flex-shrink: 0;
		transition: opacity 0.3s;
		
		@include is-screen-max($breakpoint-xl) {
			grid-column: 1 / 2;
			grid-row: 1 / 4;
		}

		&:hover {
			@include is-screen-min($breakpoint-sm) {
				opacity: 0.6;
			}
		}

		img {
			height: 35px;

			@include is-screen-max($breakpoint-lg) {
				height: 40px;
			}

			@include is-screen-max($breakpoint-sm) {
				height: 33px;
			}
		}
	}

	&__block {
		display: flex;
		flex-direction: column;

		@include is-screen-max($breakpoint-xl) {
			grid-column: 2 / 3;

			@for $i from 1 through 3 {
				&:nth-child(#{$i + 1}) {
					grid-row: #{$i} / #{$i + 1};
				}
			}
		}

		@include is-screen-max($breakpoint-sm) {
			justify-self: center;
			width: 300px;
			max-width: 100%;
		}

		p {
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			color: #FFFFFF;

			@include is-screen-max($breakpoint-xl) {
				text-align: right;
			}

			@include is-screen-max($breakpoint-lg) {
				line-height: 20px;
			}

			@include is-screen-max($breakpoint-sm) {
				font-size: 12px;
				text-align: center;
			}

			a {
				transition: opacity 0.3s;

				&:hover {
					@include is-screen-min($breakpoint-sm) {
						opacity: 0.6;
					}
				}
			}
		}
	}
}