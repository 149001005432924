.header {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	height: 70px;
	width: 100vw;
	max-width: inherit;
	z-index: 60;

	@include is-screen-max($breakpoint-sm) {
		height: 50px;
	}

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: #171717;
		opacity: 0.3;
		z-index: -1;
		transition: opacity 0.3s;

		@include is-screen-max($breakpoint-sm) {
			opacity: 0.5;
		}
	}

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__logo {
		transition: opacity 0.3s;

		img {
			height: 28px;

			@include is-screen-max($breakpoint-sm) {
				height: 26px;
			}
		}

		&:hover {
			@include is-screen-min($breakpoint-sm) {
				opacity: 0.6;
			}
		}
	}

	&__menu {
		@include is-screen-max($breakpoint-sm) {
			position: absolute;
			left: 0;
			top: 50px;
			transform: translateX(-100%);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100vw;
			height: 100vh;
			padding: 40px;
			background-color: rgba(23, 23, 23, 0.5);
			pointer-events: none;
			overflow: hidden;
			transition: transform 0.3s, background-color 0.3s;
			z-index: 80;
		}

		.menu {
			&__content {
				display: flex;
				justify-content: flex-end;
				flex-wrap: wrap;
				gap: 28px;

				@include is-screen-max($breakpoint-sm) {
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}
			}

			&__item {
				position: relative;
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
				color: #FFFFFF;
				
				@include is-screen-max($breakpoint-sm) {
					font-size: 24px;
					line-height: 30px;
				}

				&::after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					transform: translateY(10px);
					height: 3.5px;
					opacity: 0;
					background-color: $color-primary;
					transition: opacity 0.3s, transform 0.3s;
				}

				&:hover {
					&::after {
						@include is-screen-min($breakpoint-sm) {
							transform: translateY(100%);
							opacity: 1;
						}
					}
				}
			}
		}
	}

	&__burger {
		position: relative;
		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 21px;
		height: 21px;
		cursor: pointer;

		@include is-screen-max($breakpoint-sm) {
			display: flex;
		}
		
		span {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 2px;
			border-radius: 2px;
			background-color: #FFFFFF;
			transform-origin: center;
			transition: transform 0.3s, opacity 0.3s;
			
			&:nth-child(1) {
				transform: translate(-50%, calc(-50% - 5px));
			}

			&:nth-child(3) {
				transform: translate(-50%, calc(-50% + 5px));
			}
		}
	}

	&.show-menu {
		&::before {
			@include is-screen-max($breakpoint-sm) {
				opacity: 0.8;
			}
		}

		.header {
			&__menu {
				@include is-screen-max($breakpoint-sm) {
					transform: none;
					pointer-events: initial;
					background-color: rgba(23, 23, 23, 0.8);
				}
			}

			&__burger {
				span {
					&:nth-child(1) {
						transform: translate(-50%, -50%) rotate(45deg);
					}

					&:nth-child(2) {
						opacity: 0;
					}

					&:nth-child(3) {
						transform: translate(-50%, -50%) rotate(-45deg);
					}
				}
			}
		}
	}
}