@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-ThinItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-RegularItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0;
  background-color: #FFFFFF;
  font: normal 400 24px/40px "Montserrat", sans-serif;
  color: #000000;
  cursor: default;
}
body::-webkit-scrollbar {
  height: 0;
  width: 2px;
}
body::-webkit-scrollbar-track {
  background: transparent;
}
body::-webkit-scrollbar-button {
  display: none;
}
body::-webkit-scrollbar-thumb {
  background-color: #CAB95D;
}
body.no-scroll {
  overflow: hidden;
}
body * {
  box-sizing: border-box;
  margin: 0;
}
body img, body svg {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
body img path, body svg path {
  transition: fill 0.3s;
}
body iframe, body video {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
body a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}
body .disabled,
body .disabled * {
  pointer-events: none;
  cursor: not-allowed;
}
body .container {
  display: flex;
  justify-content: center;
}
body .container__content {
  width: 1680px;
  max-width: calc(100% - 54px);
}

.header {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  height: 70px;
  width: 100vw;
  max-width: inherit;
  z-index: 60;
}
@media screen and (max-width: 767.9px) {
  .header {
    height: 50px;
  }
}
.header::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #171717;
  opacity: 0.3;
  z-index: -1;
  transition: opacity 0.3s;
}
@media screen and (max-width: 767.9px) {
  .header::before {
    opacity: 0.5;
  }
}
.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__logo {
  transition: opacity 0.3s;
}
.header__logo img {
  height: 28px;
}
@media screen and (max-width: 767.9px) {
  .header__logo img {
    height: 26px;
  }
}
@media screen and (min-width: 768px) {
  .header__logo:hover {
    opacity: 0.6;
  }
}
@media screen and (max-width: 767.9px) {
  .header__menu {
    position: absolute;
    left: 0;
    top: 50px;
    transform: translateX(-100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 40px;
    background-color: rgba(23, 23, 23, 0.5);
    pointer-events: none;
    overflow: hidden;
    transition: transform 0.3s, background-color 0.3s;
    z-index: 80;
  }
}
.header__menu .menu__content {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 28px;
}
@media screen and (max-width: 767.9px) {
  .header__menu .menu__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.header__menu .menu__item {
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}
@media screen and (max-width: 767.9px) {
  .header__menu .menu__item {
    font-size: 24px;
    line-height: 30px;
  }
}
.header__menu .menu__item::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(10px);
  height: 3.5px;
  opacity: 0;
  background-color: #CAB95D;
  transition: opacity 0.3s, transform 0.3s;
}
@media screen and (min-width: 768px) {
  .header__menu .menu__item:hover::after {
    transform: translateY(100%);
    opacity: 1;
  }
}
.header__burger {
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  cursor: pointer;
}
@media screen and (max-width: 767.9px) {
  .header__burger {
    display: flex;
  }
}
.header__burger span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: #FFFFFF;
  transform-origin: center;
  transition: transform 0.3s, opacity 0.3s;
}
.header__burger span:nth-child(1) {
  transform: translate(-50%, calc(-50% - 5px));
}
.header__burger span:nth-child(3) {
  transform: translate(-50%, calc(-50% + 5px));
}
@media screen and (max-width: 767.9px) {
  .header.show-menu::before {
    opacity: 0.8;
  }
}
@media screen and (max-width: 767.9px) {
  .header.show-menu .header__menu {
    transform: none;
    pointer-events: initial;
    background-color: rgba(23, 23, 23, 0.8);
  }
}
.header.show-menu .header__burger span:nth-child(1) {
  transform: translate(-50%, -50%) rotate(45deg);
}
.header.show-menu .header__burger span:nth-child(2) {
  opacity: 0;
}
.header.show-menu .header__burger span:nth-child(3) {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.footer {
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: #171717;
}
@media screen and (max-width: 767.9px) {
  .footer {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}
.footer__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 21px;
}
@media screen and (max-width: 1439.9px) {
  .footer__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
  }
}
@media screen and (max-width: 767.9px) {
  .footer__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }
}
.footer__logo {
  flex-shrink: 0;
  transition: opacity 0.3s;
}
@media screen and (max-width: 1439.9px) {
  .footer__logo {
    grid-column: 1/2;
    grid-row: 1/4;
  }
}
@media screen and (min-width: 768px) {
  .footer__logo:hover {
    opacity: 0.6;
  }
}
.footer__logo img {
  height: 35px;
}
@media screen and (max-width: 1239.9px) {
  .footer__logo img {
    height: 40px;
  }
}
@media screen and (max-width: 767.9px) {
  .footer__logo img {
    height: 33px;
  }
}
.footer__block {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1439.9px) {
  .footer__block {
    grid-column: 2/3;
  }
  .footer__block:nth-child(2) {
    grid-row: 1/2;
  }
  .footer__block:nth-child(3) {
    grid-row: 2/3;
  }
  .footer__block:nth-child(4) {
    grid-row: 3/4;
  }
}
@media screen and (max-width: 767.9px) {
  .footer__block {
    justify-self: center;
    width: 300px;
    max-width: 100%;
  }
}
.footer__block p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
}
@media screen and (max-width: 1439.9px) {
  .footer__block p {
    text-align: right;
  }
}
@media screen and (max-width: 1239.9px) {
  .footer__block p {
    line-height: 20px;
  }
}
@media screen and (max-width: 767.9px) {
  .footer__block p {
    font-size: 12px;
    text-align: center;
  }
}
.footer__block p a {
  transition: opacity 0.3s;
}
@media screen and (min-width: 768px) {
  .footer__block p a:hover {
    opacity: 0.6;
  }
}

.page-home__block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-home__block:nth-child(2n)::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-color: #F4F4F4;
  z-index: -1;
}
.page-home__subtitle {
  margin: 0 auto 30px;
  border-bottom: 6px solid #CAB95D;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #171717;
  text-align: center;
  z-index: 10;
}
@media screen and (max-width: 767.9px) {
  .page-home__subtitle {
    margin-bottom: 20px;
    padding-bottom: 3px;
    border-bottom-width: 5px;
    font-size: 24px;
    line-height: 30px;
  }
}
.page-home__subtitle--white {
  color: #FFFFFF;
}
.page-home .b-banner {
  position: relative;
  width: 100vw;
  min-height: 635px;
  background-image: url(../img/images/banner.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 10;
}
@media screen and (max-width: 1239.9px) {
  .page-home .b-banner {
    min-height: 600px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .b-banner {
    min-height: 550px;
    background-image: url(../img/images/banner-mob.jpg);
    background-position-x: 40%;
  }
}
.page-home .b-banner__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  width: 700px;
  height: 100%;
  max-width: 100%;
  padding-top: 30px;
  padding-left: 50px;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-banner__content {
    justify-content: flex-end;
    gap: 45px;
    width: 300px;
    margin: 0 auto;
    padding: 0 0 60px;
  }
}
.page-home .b-banner__text {
  position: relative;
  font-weight: 800;
  font-size: 60px;
  line-height: 80px;
  color: #FFFFFF;
}
@media screen and (max-width: 1239.9px) {
  .page-home .b-banner__text {
    line-height: 70px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .b-banner__text {
    padding-left: 24px;
    font-size: 36px;
    line-height: 45px;
  }
}
.page-home .b-banner__text span {
  color: #CAB95D;
}
.page-home .b-banner__text::before {
  content: "";
  position: absolute;
  top: 5%;
  bottom: 5%;
  left: -50px;
  width: 4px;
  background-color: #CAB95D;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-banner__text::before {
    left: 8px;
    width: 2px;
  }
}
.page-home .b-banner__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 24px 40px;
  border: 2px solid #CAB95D;
  border-radius: 100px;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-banner__btn {
    width: 100%;
    justify-content: flex-end;
    gap: 30px;
    padding: 21px 30px;
    border-width: 3px;
  }
}
.page-home .b-banner__btn p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #CAB95D;
  transition: color 0.3s;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-banner__btn p {
    font-size: 20px;
    line-height: 24px;
  }
}
.page-home .b-banner__btn img {
  width: 30px;
  transition: transform 0.3s;
}
@media screen and (min-width: 768px) {
  .page-home .b-banner__btn:hover img {
    transform: translateX(30%);
  }
}
.page-home .b-about {
  padding-top: 60px;
  padding-bottom: 100px;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-about {
    padding-top: 45px;
    padding-bottom: 56px;
  }
}
.page-home .b-about__text {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-about__text {
    gap: 20px;
  }
}
.page-home .b-about__text p {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-about__text p {
    font-size: 14px;
    line-height: 20px;
  }
}
.page-home .b-services {
  padding-top: 25px;
  padding-bottom: 60px;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-services {
    padding-top: 45px;
    padding-bottom: 25px;
  }
}
.page-home .b-services__text {
  max-width: 100%;
  margin: 0 auto 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #808080;
  text-align: center;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-services__text {
    width: 270px;
    line-height: 25px;
  }
}
.page-home .b-services__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 45px 30px;
}
@media screen and (max-width: 1023.9px) {
  .page-home .b-services__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .b-services__grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}
.page-home .b-services__grid .service {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-services__grid .service {
    gap: 0;
  }
}
.page-home .b-services__grid .service__img {
  width: 100%;
  height: 240px;
  border-radius: 10px;
  overflow: hidden;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-services__grid .service__img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: -1;
  }
}
.page-home .b-services__grid .service__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-home .b-services__grid .service__body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-services__grid .service__body {
    justify-content: center;
    min-height: 220px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    overflow: hidden;
  }
}
.page-home .b-services__grid .service__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #020202;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-services__grid .service__title {
    padding-left: 7px;
    font-size: 32px;
    line-height: 38px;
    color: #CAB95D;
  }
}
.page-home .b-services__grid .service__text {
  width: 100%;
  max-width: 360px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ABABAB;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-services__grid .service__text {
    max-width: 100%;
    padding: 0 7px;
    color: #FFFFFF;
  }
}
.page-home .b-management {
  padding-top: 20px;
  padding-bottom: 60px;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-management {
    padding-top: 35px;
    padding-bottom: 30px;
  }
}
.page-home .b-management__grid {
  display: flex;
  gap: 30px;
  max-width: 100%;
}
@media screen and (max-width: 1239.9px) {
  .page-home .b-management__grid {
    gap: 20px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .b-management__grid {
    flex-direction: column;
    gap: 10px;
  }
}
.page-home .b-management__grid .manager {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-management__grid .manager {
    gap: 15px;
  }
}
.page-home .b-management__grid .manager__photo {
  position: relative;
  display: flex;
  justify-content: center;
}
.page-home .b-management__grid .manager__photo::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 77%;
  background-color: #CAB95D;
  z-index: -1;
}
.page-home .b-management__grid .manager__photo img {
  width: 100%;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-management__grid .manager__photo img {
    width: auto;
    max-height: 300px;
  }
}
.page-home .b-management__grid .manager__body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-management__grid .manager__body {
    gap: 4px;
  }
}
.page-home .b-management__grid .manager__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #020202;
  text-align: center;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-management__grid .manager__name {
    font-size: 20px;
  }
}
.page-home .b-management__grid .manager__position {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #BABABA;
  text-align: center;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-management__grid .manager__position {
    font-size: 20px;
  }
}
.page-home .b-text {
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-text {
    gap: 35px;
    padding-top: 40px;
    padding-bottom: 45px;
  }
}
.page-home .b-text .textblock {
  display: flex;
  flex-direction: column;
  gap: 35px;
}
@media screen and (max-width: 1239.9px) {
  .page-home .b-text .textblock {
    gap: 30px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .b-text .textblock {
    gap: 20px;
  }
}
.page-home .b-text .textblock__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #CAB95D;
  text-align: center;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-text .textblock__title {
    font-size: 20px;
  }
}
.page-home .b-text .textblock__content {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-text .textblock__content {
    font-size: 14px;
    line-height: 20px;
  }
}
.page-home .b-contacts {
  position: relative;
  width: 100vw;
  padding-top: 45px;
  padding-bottom: 100px;
  z-index: 10;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-contacts {
    padding-top: 25px;
    padding-bottom: 45px;
  }
}
.page-home .b-contacts::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-image: url(../img/images/contacts.jpg);
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.page-home .b-contacts::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-color: #171717;
  opacity: 0.9;
  z-index: -1;
}
.page-home .b-contacts__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-home .b-contacts__text {
  padding-top: 20px;
  margin-bottom: 50px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  text-align: center;
}
@media screen and (max-width: 1239.9px) {
  .page-home .b-contacts__text {
    padding-top: 15px;
  }
}
@media screen and (max-width: 767.9px) {
  .page-home .b-contacts__text {
    padding-top: 10px;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 20px;
  }
}
.page-home .b-contacts__links {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-contacts__links {
    gap: 30px;
  }
}
.page-home .b-contacts__link {
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #CAB95D;
  text-align: center;
}
@media screen and (max-width: 767.9px) {
  .page-home .b-contacts__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    line-height: 30px;
  }
}
.page-home .b-contacts__link a {
  color: #FFFFFF;
  transition: opacity 0.3s;
}
@media screen and (min-width: 768px) {
  .page-home .b-contacts__link a:hover {
    opacity: 0.6;
  }
}