.page-home {
    &__block {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:nth-child(2n) {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100vw;
                background-color: #F4F4F4;
                z-index: -1;
            }
        }
    }

    &__subtitle {
        margin: 0 auto 30px;
        border-bottom: 6px solid $color-primary;
        font-weight: 700;
        font-size: 40px;
        line-height: 50px;
        color: #171717;
        text-align: center;
        z-index: 10;

        @include is-screen-max($breakpoint-sm) {
            margin-bottom: 20px;
            padding-bottom: 3px;
            border-bottom-width: 5px;
            font-size: 24px;
            line-height: 30px;
        }

        &--white {
            color: #FFFFFF;
        }
    }
    
    .b-banner {
        position: relative;
        width: 100vw;
        min-height: 635px;
        background-image: url(../img/images/banner.jpg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 10;

        @include is-screen-max($breakpoint-lg) {
            min-height: 600px;
        }

        @include is-screen-max($breakpoint-sm) {
            min-height: 550px;
            background-image: url(../img/images/banner-mob.jpg);
            background-position-x: 40%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 50px;
            width: 700px;
            height: 100%;
            max-width: 100%;
            padding-top: 30px;
            padding-left: 50px;
    
            @include is-screen-max($breakpoint-sm) {
                justify-content: flex-end;
                gap: 45px;
                width: 300px;
                margin: 0 auto;
                padding: 0 0 60px;
            }
        }

        &__text {
            position: relative;
            font-weight: 800;
            font-size: 60px;
            line-height: 80px;
            color: #FFFFFF;

            @include is-screen-max($breakpoint-lg) {
                line-height: 70px;
            }
    
            @include is-screen-max($breakpoint-sm) {
                padding-left: 24px;
                font-size: 36px;
                line-height: 45px;
            }

            span {
                color: $color-primary;
            }

            &::before {
                content: "";
                position: absolute;
                top: 5%;
                bottom: 5%;
                left: -50px;
                width: 4px;
                background-color: $color-primary;
                
                @include is-screen-max($breakpoint-sm) {
                    left: 8px;
                    width: 2px;
                }
            }
        }

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 24px 40px;
            border: 2px solid $color-primary;
            border-radius: 100px;
    
            @include is-screen-max($breakpoint-sm) {
                width: 100%;
                justify-content: flex-end;
                gap: 30px;
                padding: 21px 30px;
                border-width: 3px;
            }

            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: $color-primary;
                transition: color 0.3s;
        
                @include is-screen-max($breakpoint-sm) {
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            img {
                width: 30px;
                transition: transform 0.3s;
            }

            &:hover {
                img {
                    @include is-screen-min($breakpoint-sm) {
                        transform: translateX(30%);
                    }
                }
            }
        }
    }

    .b-about {
        padding-top: 60px;
        padding-bottom: 100px;

        @include is-screen-max($breakpoint-sm) {
            padding-top: 45px;
            padding-bottom: 56px;
        }

        &__text {
            display: flex;
            flex-direction: column;
            gap: 30px;
    
            @include is-screen-max($breakpoint-sm) {
                gap: 20px;
            }

            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: #000000;
        
                @include is-screen-max($breakpoint-sm) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    .b-services {
        padding-top: 25px;
        padding-bottom: 60px;

        @include is-screen-max($breakpoint-sm) {
            padding-top: 45px;
            padding-bottom: 25px;
        }

        &__text {
            max-width: 100%;
            margin: 0 auto 30px;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #808080;
            text-align: center;
    
            @include is-screen-max($breakpoint-sm) {
                width: 270px;
                line-height: 25px;
            }
        }

        &__grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 45px 30px;

            @include is-screen-max($breakpoint-md) {
                grid-template-columns: repeat(2, 1fr);
            }
    
            @include is-screen-max($breakpoint-sm) {
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;
            }

            .service {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 20px;
        
                @include is-screen-max($breakpoint-sm) {
                    gap: 0;
                }

                &__img {
                    width: 100%;
                    height: 240px;
                    border-radius: 10px;
                    overflow: hidden;
            
                    @include is-screen-max($breakpoint-sm) {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        height: 100%;
                        z-index: -1;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &__body {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
            
                    @include is-screen-max($breakpoint-sm) {
                        justify-content: center;
                        min-height: 220px;
                        padding: 20px;
                        background-color: rgba(0, 0, 0, 0.7);
                        border-radius: 10px;
                        overflow: hidden;
                    }
                }

                &__title {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 28px;
                    color: #020202;
            
                    @include is-screen-max($breakpoint-sm) {
                        padding-left: 7px;
                        font-size: 32px;
                        line-height: 38px;
                        color: $color-primary;
                    }
                }

                &__text {
                    width: 100%;
                    max-width: 360px;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    color: #ABABAB;
            
                    @include is-screen-max($breakpoint-sm) {
                        max-width: 100%;
                        padding: 0 7px;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

    .b-management {
        padding-top: 20px;
        padding-bottom: 60px;

        @include is-screen-max($breakpoint-sm) {
            padding-top: 35px;
            padding-bottom: 30px;
        }

        &__grid {
            display: flex;
            gap: 30px;
            max-width: 100%;

            @include is-screen-max($breakpoint-lg) {
                gap: 20px;
            }
    
            @include is-screen-max($breakpoint-sm) {
                flex-direction: column;
                gap: 10px;
            }

            .manager {
                display: flex;
                flex-direction: column;
                gap: 30px;
        
                @include is-screen-max($breakpoint-sm) {
                    gap: 15px;
                }

                &__photo {
                    position: relative;
                    display: flex;
                    justify-content: center;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 77%;
                        background-color: $color-primary;
                        z-index: -1;
                    }

                    img {
                        width: 100%;
                        
                        @include is-screen-max($breakpoint-sm) {
                            width: auto;
                            max-height: 300px;
                        }
                    }
                }

                &__body {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
            
                    @include is-screen-max($breakpoint-sm) {
                        gap: 4px;
                    }
                }

                &__name {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    color: #020202;
                    text-align: center;
            
                    @include is-screen-max($breakpoint-sm) {
                        font-size: 20px;
                    }
                }

                &__position {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    color: #BABABA;
                    text-align: center;
            
                    @include is-screen-max($breakpoint-sm) {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .b-text {
        display: flex;
        flex-direction: column;
        gap: 45px;
        padding-top: 60px;
        padding-bottom: 60px;

        @include is-screen-max($breakpoint-sm) {
            gap: 35px;
            padding-top: 40px;
            padding-bottom: 45px;
        }

        .textblock {
            display: flex;
            flex-direction: column;
            gap: 35px;

            @include is-screen-max($breakpoint-lg) {
                gap: 30px;
            }
    
            @include is-screen-max($breakpoint-sm) {
                gap: 20px;
            }

            &__title {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: $color-primary;
                text-align: center;
        
                @include is-screen-max($breakpoint-sm) {
                    font-size: 20px;
                }
            }

            &__content {
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
                color: #000000;
        
                @include is-screen-max($breakpoint-sm) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    .b-contacts {
        position: relative;
        width: 100vw;
        padding-top: 45px;
        padding-bottom: 100px;
        z-index: 10;

        @include is-screen-max($breakpoint-sm) {
            padding-top: 25px;
            padding-bottom: 45px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            background-image: url(../img/images/contacts.jpg);
            background-position: center top;
            background-size: cover;
            background-repeat: no-repeat;
            z-index: -1;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            background-color: #171717;
            opacity: 0.9;
            z-index: -1;
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__text {
            padding-top: 20px;
            margin-bottom: 50px;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #FFFFFF;
            text-align: center;

            @include is-screen-max($breakpoint-lg) {
                padding-top: 15px;
            }
    
            @include is-screen-max($breakpoint-sm) {
                padding-top: 10px;
                margin-bottom: 30px;
                font-size: 14px;
                line-height: 20px;
            }
        }

        &__links {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include is-screen-max($breakpoint-sm) {
                gap: 30px;
            }
        }

        &__link {
            font-weight: 500;
            font-size: 24px;
            line-height: 35px;
            color: $color-primary;
            text-align: center;
    
            @include is-screen-max($breakpoint-sm) {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 24px;
                line-height: 30px; 
            }

            a {
                color: #FFFFFF;
                transition: opacity 0.3s;

                &:hover {
                    @include is-screen-min($breakpoint-sm) {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}