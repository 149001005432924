$types: "Thin" 200, "ExtraLight" 200, "Light" 300, "Regular" 400, "Medium" 500, "SemiBold" 600, "Bold" 700, "ExtraBold" 800, "Black" 900;
@each $type, $weight in $types {
    @font-face {
        font-family: "Montserrat";
        src: url("../fonts/Montserrat/Montserrat-#{$type}.ttf") format("truetype");
        font-weight: $weight;
        font-style: normal;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("../fonts/Montserrat/Montserrat-#{$type}Italic.ttf") format("truetype");
        font-weight: $weight;
        font-style: italic;
    }
}